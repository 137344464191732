import { Controller } from "stimulus";
import { count } from "@wordpress/wordcount";

export default class extends Controller {
  static targets = ["textArea", "sendButton", "notice"];

  connect() {
    this.textAreaTarget.addEventListener("input", this.onInput);
  }

  onInput = (event) => {
    const indel = document.querySelector("#innerWindow");
    indel.style.paddingBottom = `${
      this.element.getBoundingClientRect().height
    }px`;
    const el = document.querySelector("#window");
    el.scrollTop = el.scrollHeight - el.clientHeight;

    const withinLimit = this.isWithinLimit(event.target.value);

    if (withinLimit) {
      if (this.element.classList.contains("bg-pinky")) {
        this.element.classList.remove("bg-pinky");

        this.sendButtonTarget.classList.remove("bg-black-30");
        this.sendButtonTarget.classList.add("pointer");
        this.sendButtonTarget.classList.add("dim");
        this.noticeTarget.classList.add("dn");
      }
    } else {
      if (!this.element.classList.contains("bg-pinky")) {
        this.element.classList.add("bg-pinky");

        this.sendButtonTarget.classList.add("bg-black-30");
        this.sendButtonTarget.classList.remove("pointer");
        this.sendButtonTarget.classList.remove("dim");
        this.noticeTarget.classList.remove("dn");
      }
    }

    this.sendButtonTarget.disabled = !withinLimit;
  };

  isWithinLimit = (str) => {
    return count(str, "words", {}) <= this.maxWordCount;
  };

  get maxWordCount() {
    return parseInt(this.element.getAttribute("data-word-limit"), 10) || 50;
  }
}
