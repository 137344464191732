import { Controller } from "stimulus";

const INITIAL_DELAY_TIME = 1000;
const DELAY_TIME = 3000;

export default class extends Controller {
  static targets = [
    "messageOne",
    "messageTwo",
    "messageThree",
    "messageFour",
    "typingIndicator",
  ];

  _stepIndex = 0;
  _interval = null;

  connect() {
    this._interval = setInterval(this.onTick, INITIAL_DELAY_TIME);
  }

  onTick = () => {
    switch (this._stepIndex) {
      case 0:
        clearInterval(this._interval);
        this.messageTwoTarget.classList.remove("dn");
        this._stepIndex = this._stepIndex + 1;
        this._interval = setInterval(this.onTick, DELAY_TIME);
        break;
      case 1:
        this.messageThreeTarget.classList.remove("dn");
        this._stepIndex = this._stepIndex + 1;
        break;
      case 2:
        this.messageFourTarget.classList.remove("dn");
        this.typingIndicatorTarget.classList.add("dn");
        this._stepIndex = this._stepIndex + 1;
        break;
      default:
        clearInterval(this._interval);
      // Handle
    }
  };
}
