import { Controller } from "stimulus";
import { diffWordsWithSpace } from "diff";

export default class extends Controller {
  static targets = ["reviewContent"];

  connect() {
    this.reviewContentTarget.innerText = "";
    let color = "";
    let el = null;
    const result = diffWordsWithSpace(this.answerContent, this.reviewContent);
    const fragment = document.createDocumentFragment();

    result.forEach((part) => {
      color = part.added ? "primary b" : part.removed ? "cstrike" : "";
      el = document.createElement(part.removed ? "del" : "span");
      el.className = color;
      el.appendChild(document.createTextNode(part.value));

      fragment.appendChild(el);
    });

    this.reviewContentTarget.appendChild(fragment);
  }

  get answerContent() {
    return this.element.getAttribute("data-answer-content");
  }

  get reviewContent() {
    return this.element.getAttribute("data-review-content");
  }
}
