import { Controller } from "stimulus";

const fieldSetName = '[data-target="formrepeater.fieldset"]';
/**
 * Parses a string for indexes and replaces n-th occurrence with given value
 *
 * @param {string} source String to parse for given pattern
 * @param {*} pattern Regex pattern, i.e. /\d+/g
 * @param {*} replacement Value to replace the current one with
 * @param {*} n Depth
 */
const replaceNthOccurence = (source, pattern, replacement, n) => {
  var substr = "";
  while ((substr = pattern.exec(source))) {
    if (--n === 0) {
      source =
        source.slice(0, substr.index) +
        replacement +
        source.slice(pattern.lastIndex);
      break;
    }
  }
  return source;
};

/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
const getClosest = (elem, selector) => {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get closest match
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }

  return null;
};

export default class extends Controller {
  static targets = ["add", "fieldset"];

  connect() {}

  add() {
    const depth =
      parseInt(this.fieldsetTarget.getAttribute("data-depth"), 10) || 1;
    const el = this.fieldsetTarget.cloneNode(true);
    const nextIndex = this.countFieldSets(depth);

    el.classList.remove("dn"); // Ensure it's visible

    // Update all labels
    el.querySelectorAll("label").forEach((input) => {
      input.setAttribute(
        "for",
        replaceNthOccurence(input.getAttribute("for"), /\d+/g, nextIndex, depth)
      );
    });

    // Reset all inputs within and assign new `name` and `id attributes
    el.querySelectorAll("input, select").forEach((input) => {
      if (input.type === "number") {
        input.value = 0;
      } else if (input.type !== "radio" && input.type !== "checkbox") {
        // Check for the defaulting hidden field accompanied with a checkbox (rails-specific)
        if (
          (input.type === "hidden" &&
            input.nextSibling &&
            input.nextSibling.type === "checkbox") ||
          input.name.includes("zero_stock_rule")
        ) {
          // Nothing…
        } else {
          input.value = null;
        }
      } else {
        input.checked = false;
        input.selected = false;
      }

      // Ensure the next index
      input.setAttribute(
        "name",
        replaceNthOccurence(
          input.getAttribute("name"),
          /\d+/g,
          nextIndex,
          depth
        )
      );

      input.setAttribute(
        "id",
        replaceNthOccurence(input.getAttribute("id"), /\d+/g, nextIndex, depth)
      );
    });

    // Insert just before the add button
    this.element.insertBefore(el, this.addTarget);
  }

  delete(event) {
    const fieldsetCount = this.element.querySelectorAll(fieldSetName);
    const parent = getClosest(event.target, fieldSetName);

    if (parent && fieldsetCount.length > 1) {
      const deleteCheckbox = parent.querySelector(".input-delete-field");

      if (deleteCheckbox) {
        deleteCheckbox.setAttribute("value", "1");
      }

      parent.classList.add("dn");
    }
  }

  countFieldSets(depth) {
    return this.element.querySelectorAll(
      `[data-target="formrepeater.fieldset"][data-depth="${depth}"]`
    ).length;
  }
}
