// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks");
require("@rails/activestorage");
require("@rails/actiontext");
require("channels");
require("trix");
require("diff");

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// Configure Trix to have a custom strike-through keyboard shortcut
addEventListener("trix-initialize", (event) => {
  const { toolbarElement } = event.target;

  const el = toolbarElement.querySelector('[data-trix-attribute="strike"]');
  console.log(el);
  el.setAttribute("data-trix-key", "u");
});
