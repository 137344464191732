import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['aiCorrectedAnswer'];

  connect() {
    if (this.hasAiCorrectedAnswerTarget) {
      const messageTexts = this.answerTexts();
      const result = this.comparisonResult(messageTexts[0], messageTexts[1]);
      this.aiCorrectedAnswerTarget.innerHTML = result.trim();
    }
  }

  answerTexts() {
    const answerId = this.aiCorrectedAnswerTarget.dataset.answerId;
    // all elements in chat
    const chatElements = document.querySelector('#innerWindow').children;
    // only elements with messages
    const messages = Array.from(chatElements).filter(child => child.classList.contains('flex-column'));
    // only elements related to the current answerID
    const answerMessages = messages.filter(message => message.querySelector(`div[data-answer-id="${answerId}"]`));
    // actual message texts
    const messageTexts = answerMessages.map(el => {
      return el.querySelector(`div[data-answer-id="${answerId}"]`).innerText;
    });

    // first element is the original message, second element is AI corrected message
    return messageTexts;
  }

  // Normalize the word by removing specified symbols and converting to lowercase
  normalizeWord(string) {
    return string.toLowerCase().replace(/[.,'"’´]/g, '');
  }

  // split text to words and normalize each word
  normalizeText(text) {
    const result = [];
    text.split(/\s+/).forEach(word => {
      result.push(this.normalizeWord(word));
    });
    return result;
  }

  // Create a map of original words
  createMap(text) {
    const map = [];
    text.split(/\s+/).forEach(word => {
      map.push(word);
    });
    return map;
  }

  comparisonResult(oldText, newText) {
    const Diff = require('diff');

    // Create maps so we can use original words in the result text
    const oldMap = this.createMap(oldText);
    const newMap = this.createMap(newText);

    const normalizedOldText = this.normalizeText(oldText);
    const normalizedNewText = this.normalizeText(newText);

    // Generate diff based on normalized texts
    const diff = Diff.diffArrays(normalizedNewText, normalizedOldText);

    // Format the output
    let result = '';
    let oldMapIndex = 0, newMapIndex = 0;

    diff.forEach((part) => {
      const words = part.value;
      words.forEach(word => {
        if (word) {
          if (part.added) {
            result += `<del>${oldMap[oldMapIndex++]}</del> `;
          } else if (part.removed) {
            result += `<strong>${newMap[newMapIndex++]}</strong> `;
          } else {
            const newMapItem = newMap[newMapIndex++];
            if (newMapItem != undefined) {
              result += `${newMapItem} `;
            }
            oldMapIndex++;
          }
        }
      });
    });

    return result;
  }
}
