import { Controller } from "stimulus";

export const SHOW_ACTION = "reaction.show";
export const HIDE_ACTION = "reaction.hide";

export default class extends Controller {
  static targets = ["button", "messageBox"];

  onClick = () => {
    window.dispatchEvent(
      new CustomEvent(SHOW_ACTION, {
        detail: {
          url: this.buttonTarget.getAttribute("data-reaction-path"),
          id: this.buttonTarget.getAttribute("data-review-id"),
        },
      })
    );
  };
}
