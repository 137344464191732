import { Controller } from "stimulus";

const clearSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

const strip = (str) => {
  return str.replace(/^\s+|\s+$/g, "");
};

/**
 * Parses a string for indexes and replaces n-th occurrence with given value
 *
 * @param {string} source String to parse for given pattern
 * @param {*} pattern Regex pattern, i.e. /\d+/g
 * @param {*} replacement Value to replace the current one with
 * @param {*} n Depth
 */
const replaceNthOccurence = (source, pattern, replacement, n) => {
  var substr = "";
  while ((substr = pattern.exec(source))) {
    if (--n === 0) {
      source =
        source.slice(0, substr.index) +
        replacement +
        source.slice(pattern.lastIndex);
      break;
    }
  }
  return source;
};

export default class extends Controller {
  static targets = ["list", "addButton", "categoryField", "inputField"];

  connect() {
    document.addEventListener("selectionchange", this.onSelectionChange);
    this.inputFieldTarget.addEventListener("input", this.toggleDisabledState);
    this.categoryFieldTarget.addEventListener(
      "input",
      this.toggleDisabledState
    );

    this.toggleDisabledState();
  }

  disconnect() {
    document.removeEventListener("selectionchange", this.onSelectionChange);
    this.inputFieldTarget.removeEventListener(
      "input",
      this.toggleDisabledState
    );
    this.categoryFieldTarget.removeEventListener(
      "input",
      this.toggleDisabledState
    );
  }

  toggleDisabledState = () => {
    // this.addButtonTarget.disabled = this.isDisabled;
    // if (this.isDisabled) {
    //   this.addButtonTarget.classList.add("bg-gray-400");
    // } else {
    //   this.addButtonTarget.classList.remove("bg-gray-400");
    // }
  };

  onSelectionChange = () => {
    this.inputFieldTarget.value = document.getSelection();
  };

  onAdd = () => {
    if (this.isDisabled) {
      this.toggleDisabledState();
      return;
    }

    const el = this.listTarget.querySelector("tr").cloneNode(true);
    const select = el.querySelector('[data-key="review_category_id"]');
    // itemCount
    const nextIndex = this.itemCount;
    const depth = 1;

    // Update all labels
    el.querySelectorAll("label").forEach((input) => {
      input.setAttribute(
        "for",
        replaceNthOccurence(input.getAttribute("for"), /\d+/g, nextIndex, depth)
      );
    });

    // Reset all inputs within and assign new `name` and `id attributes
    el.querySelectorAll("input, select").forEach((input) => {
      if (input.type === "number") {
        input.value = 0;
      } else if (input.type !== "radio" && input.type !== "checkbox") {
        // Check for the defaulting hidden field accompanied with a checkbox (rails-specific)
        if (
          (input.type === "hidden" &&
            input.nextSibling &&
            input.nextSibling.type === "checkbox") ||
          input.name.includes("zero_stock_rule")
        ) {
          // Nothing…
        } else {
          input.value = null;
        }
      } else {
        input.checked = false;
        input.selected = false;
      }

      // Ensure the next index
      input.setAttribute(
        "name",
        replaceNthOccurence(
          input.getAttribute("name"),
          /\d+/g,
          nextIndex,
          depth
        )
      );

      input.setAttribute(
        "id",
        replaceNthOccurence(input.getAttribute("id"), /\d+/g, nextIndex, depth)
      );
    });

    Array.prototype.forEach.call(select.options, (opt, index) => {
      if (
        opt.value ===
        this.categoryFieldTarget.options[this.categoryFieldTarget.selectedIndex]
          .value
      ) {
        select.selectedIndex = index;
      }
    });

    const input = el.querySelector('[data-key="content"]');
    input.value = strip(this.inputFieldTarget.value);

    this.listTarget.appendChild(el);

    // Clear out the fields
    this.categoryFieldTarget.selectedIndex = 0;
    this.inputFieldTarget.value = "";
    clearSelection();
    this.toggleDisabledState();
  };

  get isDisabled() {
    return !this.inputFieldTarget.value;
  }

  get itemCount() {
    return this.listTarget.querySelectorAll("tr").length;
  }
}
