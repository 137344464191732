import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "notice", "textArea", "sendButton"];

  _container = null;

  connect() {
    this._wrapper = document.createElement("div");
    this._wrapper.className = "z-99 overflow-visible pa3 mw7 w-100 fixed";

    this.formTarget.addEventListener("submit", this.onSubmit);
    this.textAreaTarget.addEventListener("input", this.hideNotice);
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.onSubmit);
    this.textAreaTarget.removeEventListener("input", this.hideNotice);
  }

  onSubmit = (event) => {
    this.sendButtonTarget.value = 'Checking...';

    if (!this.options.length) {
      return;
    }

    const match = this.options.find(this.valueContains);
    if (match && !this.formTarget.classList.contains("valid")) {
      event.preventDefault();

      this.displayNotice(match[0], match[1]);
      this.connectWrapper();
    }
  };

  connectWrapper() {
    this.sendButtonTarget.disabled = false;
    this.sendButtonTarget.removeAttribute("disabled");

    const changeButton = this._wrapper.querySelector(
      '[data-button-action="change"]'
    );
    if (changeButton) {
      changeButton.addEventListener("click", this.onChangeClick);
    }

    const submitButton = this._wrapper.querySelector(
      '[data-button-action="submit"]'
    );
    if (submitButton) {
      submitButton.addEventListener("click", this.onSubmitClick);
    }
  }

  disconnectWrapper() {
    const changeButton = this._wrapper.querySelector(
      '[data-button-action="change"]'
    );
    if (changeButton) {
      changeButton.removeEventListener("click", this.onChangeClick);
    }

    const submitButton = this._wrapper.querySelector(
      '[data-button-action="submit"]'
    );
    if (submitButton) {
      submitButton.removeEventListener("click", this.onSubmitClick);
    }
  }

  valueContains = (item) => {
    return this.textAreaTarget.value.search(item[0]) >= 0;
  };

  displayNotice = (phrase, textValue) => {
    this._wrapper.innerHTML = `
      <div class="fixed absolute--fill z-99 flex items-center justify-center left-20-l">
        <div class="absolute absolute--fill bg-gray-600 z-0 o-50"></div>
        <div class="br4 bg-white z-1 shadow-xl tc overflow-hidden">
          <h3 class="f4 b bb b--black-10 ma0 pa0 bg-secondary white ph3 pv2"></h3>
          <div class="pa3">
            <h4 class="f3 lh-title mt0 mb3">${phrase}</h4>
            <p class="lh-copy f5">This is probably wrong.</p>
            <p class="lh-copy f4">${textValue}</p>
            <div class="flex flex-column flex-row-l justify-center items-center">
              <button data-button-action="change" class="mh3-l mb3 bg-primary b f5 ttu pointer dim bn br-pill lh-solid white pv2 pv3-ns ph3 ph4-ns" type="button">Change the text</button>
              <button data-button-action="submit" class="mh3-l mb3 bg-secondary b f5 ttu pointer dim bn br-pill lh-solid white pv2 pv3-ns ph3 ph4-ns" type="button">Send this text</button>
            </div>
          </div>
        </div>
      </div>
    `;
    this.attach();
  };

  hideNotice = () => {
    this.detach();
    this.disconnectWrapper();
  };

  detach = () => {
    if (!this._wrapper || !this._wrapper.parentNode) {
      return;
    }

    this._wrapper.parentNode.removeChild(this._wrapper);
  };

  attach = () => {
    if (!this._wrapper || this._wrapper.parentNode) {
      return;
    }

    document.body.appendChild(this._wrapper);
  };

  onChangeClick = () => this.hideNotice();

  onSubmitClick = () => {
    this.formTarget.classList.add("valid");
    HTMLFormElement.prototype.submit.call(this.formTarget);
  };

  get options() {
    return window.autoCorrections || [];
  }

  get isDisplayed() {
    return !!this._wrapper.parentNode;
  }
}
