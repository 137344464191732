import { Controller } from "stimulus";

const selectText = (node) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn("Could not select text in node: Unsupported browser.");
  }
};

export default class extends Controller {
  static targets = ["messageBox"];

  connect() {
    this.messageBoxTarget.querySelectorAll("del, strong").forEach((el) => {
      el.classList.add("pointer");
      el.classList.add("b");
      el.addEventListener("click", this.onClick);
    });
  }

  onClick = (ev) => {
    selectText(ev.target);
  };
}
