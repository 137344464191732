import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay", "form", "data"];

  connect() {
    window.addEventListener("reaction.show", this.onShow);
    window.addEventListener("reaction.hide", this.onHide);
  }

  disconnect() {
    window.removeEventListener("reaction.hide", this.onHide);
    window.removeEventListener("reaction.show", this.onShow);
  }

  onShow = (event) => {
    if (!event.detail) {
      return;
    }

    this.formTarget.setAttribute("action", event.detail.url);

    this.overlayTarget.classList.remove("dn");
    this.overlayTarget.classList.add("flex");
  };

  onHide = () => {
    this.overlayTarget.classList.remove("flex");
    this.overlayTarget.classList.add("dn");
  };

  onClick = (event) => {
    this.dataTarget.setAttribute(
      "value",
      event.target.getAttribute("data-reaction-id")
    );
    this.formTarget.submit();
  };
}
