import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hiddenField", "selectField", "textField"];

  connect() {
    this.selectFieldTarget.addEventListener("change", this.toggleTextField);
    this.textFieldTarget.addEventListener("input", this.onCustomInput);
    this.presentValue();
  }

  disconnect() {
    this.selectFieldTarget.removeEventListener("change", this.toggleTextField);
    this.textFieldTarget.removeEventListener("input", this.onCustomInput);
  }

  toggleTextField = () => {
    const i = this.selectFieldTarget.selectedIndex;
    const op = this.selectFieldTarget.options[i];

    if (op && op.value === "Other…") {
      this.hiddenFieldTarget.value = "";
      this.textFieldTarget.classList.remove("dn");
    } else {
      if (op) {
        this.hiddenFieldTarget.value = op.value;
      }
      this.textFieldTarget.classList.add("dn");
    }
  };

  onCustomInput = (e) => {
    this.hiddenFieldTarget.value = e.target.value;
  };

  presentValue = () => {
    const val = this.hiddenFieldTarget.value;
    let opi;
    let i;

    if (!val.length) {
      this.textFieldTarget.value = null;
      this.textFieldTarget.classList.add("dn");
      this.selectFieldTarget.selectedIndex = 0;
      return;
    }

    for (i = 0; i < this.selectFieldTarget.options.length; i++) {
      const m = this.selectFieldTarget.options[i];
      if (m.value.toLowerCase() === val.toLowerCase()) {
        console.log(opi);
        opi = i;
      }
    }

    if (opi) {
      this.selectFieldTarget.selectedIndex = opi; // find index
    } else {
      this.textFieldTarget.value = val;
      this.textFieldTarget.classList.remove("dn");
      this.selectFieldTarget.selectedIndex = 1;
    }
  };
}
