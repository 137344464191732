import { Controller } from "stimulus";

const REGEX = "\\s*";
const makeRegExp = (tag, content, whitespace) =>
  new RegExp(
    "<+[" + tag + "]+>(" + content + ")" + whitespace + "</+[" + tag + "]+>"
  );

const makeLink = (path, id, content) =>
  `<a class="link secondary dim" href="${path}/${id}">${content}</a>`;

export default class extends Controller {
  static targets = ["messageBox"];

  connect() {
    var input = this.messageBoxTarget.innerHTML;

    // 1. Replace <strong />
    this.mistakes.forEach((mistake) => {
      input = input.replace(
        makeRegExp("strong", mistake.content, ""),
        `<strong>${makeLink(
          this.mistakesPath,
          mistake.id,
          mistake.content
        )}</strong>`
      );
    });

    // 1. Replace <strong />
    this.mistakes.forEach((mistake) => {
      input = input.replace(
        makeRegExp("strong", mistake.content, REGEX),
        `<strong>${makeLink(
          this.mistakesPath,
          mistake.id,
          mistake.content
        )} </strong>`
      );
    });

    // 2. Replace <del />
    this.mistakes.forEach((mistake) => {
      input = input.replace(
        makeRegExp("del", mistake.content, ""),
        `<del class="blue">${makeLink(
          this.mistakesPath,
          mistake.id,
          mistake.content
        )}</del>`
      );
    });

    // 2. Replace <del />
    this.mistakes.forEach((mistake) => {
      input = input.replace(
        makeRegExp("del", mistake.content, REGEX),
        `<del class="blue">${makeLink(
          this.mistakesPath,
          mistake.id,
          mistake.content
        )} </del>`
      );
    });

    this.messageBoxTarget.innerHTML = input;
  }

  get mistakes() {
    try {
      let p = JSON.parse(this.element.getAttribute("data-mistakes"));
      return p;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  get mistakesPath() {
    return this.element.getAttribute("data-mistakes-path");
  }
}
