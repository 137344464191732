import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textArea"];

  hiddenDiv = null;
  content = null;

  connect() {
    this.hiddenDiv = document.createElement("div");
    this.content = null;

    // SOURCE: https://www.impressivewebs.com/textarea-auto-resize/
    // Add the "txta" styles, which are common to both textarea and hiddendiv
    // If you want, you can remove those from CSS and add them via JS
    // hiddenDiv.classList.add("txta");

    // Add the styles for the hidden div
    // These can be in the CSS, just remove these three lines and uncomment the CSS
    this.hiddenDiv.style.display = "none";
    this.hiddenDiv.style.whiteSpace = "pre-wrap";
    this.hiddenDiv.style.wordWrap = "break-word";

    this.textAreaTarget.addEventListener("input", this.calculateHeight);

    // Delay rendering…
    setTimeout(this.calculateHeight, 0);
  }

  calculateHeight = () => {
    // Append hiddendiv to parent of textarea, so the size is correct
    this.textAreaTarget.parentNode.appendChild(this.hiddenDiv);

    // Remove this if you want the user to be able to resize it in modern browsers
    this.textAreaTarget.style.resize = "none";

    // This removes scrollbars
    this.textAreaTarget.style.overflowX = "hidden";
    this.textAreaTarget.style.overflowY = "scroll";
    this.textAreaTarget.style.maxHeight = "8rem";

    // Every input/change, grab the content
    this.content = this.textAreaTarget.value;

    // Add the same content to the hidden div

    // This is for old IE
    this.content = this.content.replace(/\n/g, "<br>");

    // The <br ..> part is for old IE
    // This also fixes the jumpy way the textarea grows if line-height isn't included
    this.hiddenDiv.innerHTML = this.content + '<br style="line-height: 3px;">';

    // Briefly make the hidden div block but invisible
    // This is in order to read the height
    this.hiddenDiv.style.visibility = "hidden";
    this.hiddenDiv.style.display = "block";
    this.textAreaTarget.style.height = this.hiddenDiv.offsetHeight + 16 + "px";

    // Make the hidden div display:none again
    this.hiddenDiv.style.visibility = "visible";
    this.hiddenDiv.style.display = "none";
  };
}
